import React, { useEffect, useState } from "react";
//import { Link } from "gatsby"
import { LocalizedLink as Link } from "gatsby-theme-i18n";
import { useTranslation } from "react-i18next"
import { useLocalization } from "gatsby-theme-i18n"
import { Container, Row, Col } from "react-bootstrap";
import ScrollAnimation from 'react-animate-on-scroll';
import PropertyKeyDetails from "./PropertyKeyDetails";
import PropertyCalculator from "../PropertyCalculator/PropertyCalculator";
import PropertyDetailsMap from "../PropertyDetailsMap/PropertyDetailsMap";
import PropertyDetailsMapContent from "../PropertyDetailsMap/PropertyDetailsMapContent";
import PropertySidebar from "../PropertySidebar/PropertySidebar";
import { useAllStrapiOffice } from "../../hooks/use-all-strapioffice"
import QRImage from "../../images/dld_qr.png"
import './assets/styles/_index.scss';
import ReadMore from "../ReadMore/ReadMore";

const PropertyDescription = (props) => {
    const { t } = useTranslation()
    const { locale } = useLocalization()
    // Sticky scroll
    const [scroll, setScroll] = useState(false)
    const [renderComponent, setRenderComponent] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 750)
        })
        window.addEventListener("mousemove", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        })
        window.addEventListener("touchmove", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        })
        window.addEventListener("keypress", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        })
    }, [])
    // Sticky scroll
    let prop_desc = props?.long_description?.data?.long_description
    if (prop_desc == "") {
        prop_desc = props?.description?.data?.description
    }

    var office_data = useAllStrapiOffice()
    office_data = office_data.allStrapiOffice.nodes.filter((n) => {
        return n.locale === locale;
    });

    //office_data = office_data.allStrapiOffice.nodes
    let office_key;
    if (props?.office_mapping != "")  {
        for (let k in office_data) {
            if (props?.office_mapping == office_data[k].property_office_mapping) {
                office_key = k;
                break;
            }
        }
    }

    return (
        <section className="property-desc-wrapper" id="property-desc-wrapper">
            <Container>
                <Row>
                    <Col xl={7}>
                        <PropertyKeyDetails {...props} />

                        <Row>
                            <Col>
                                <div className="property-split-line"></div>
                            </Col>
                        </Row>

                        {prop_desc && <Row>
                            <Col lg={12}>
                                <div className="property-desc-title">{t("prop_desc")}</div>
                                <ReadMore
                                    content={prop_desc}
                                    height={270}
                                    className={"property-desc-text"}
                                />
                            </Col>
                        </Row>}

                        {prop_desc && <Row>
                            <Col>
                                <div className="property-split-line"></div>
                            </Col>
                        </Row>}

                        {props?.features && props?.features.length > 0 && <Row>
                            <Col lg={12}>
                                <ScrollAnimation animateIn="animate__fadeIn" animateOnce delay={100} offset={10}>
                                    <div className="property-desc-title">{t("features")}</div>
                                    <ul className="list-unstyled property-features-list">
                                        {(props?.features).map((feature, i) => <li>{feature}</li>)}
                                    </ul>
                                </ScrollAnimation>
                            </Col>
                        </Row>}

                        {props?.features && props?.features.length > 0 && <Row>
                            <Col>
                                <div className="property-split-line"></div>
                            </Col>
                        </Row>}

                        {props?.search_type === "sales" &&
                            <ScrollAnimation animateIn="animate__fadeIn" animateOnce delay={100} offset={10}>
                                <PropertyCalculator prop_price={props?.price} />
                            </ScrollAnimation>
                        }

                        {props?.search_type === "sales" &&
                            <Row>
                                <Col>
                                    <div className="property-split-line"></div>
                                </Col>
                            </Row>
                        }

                        <Row className="dld-section">
                            <Col lg={12}>
                                <div className="qr-code-module">
                                    <div className="image-section">
                                        <img src={QRImage} alt="qr code" />
                                    </div>
                                    <div className="qr-content">
                                        <div className="icon-text-holder">
                                            <h5>DLD Permit Number:</h5>

                                            <div className="info-icon-module">
                                                <i className="icon icon-info" />
                                                <span className="tri-icon"><i className="icon triangle-icon" /></span>
                                                <div className={`show-when-hover`}>

                                                    <p>
                                                        The Dubai Land Department strongly advises all customers and
                                                        investors to only engage with real estate advertisements that
                                                        feature the QR Code
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <p>7117733724</p>
                                        <p className="qr-text">
                                            This property listing has been reviewed and verified by Dubai Land Department
                                        </p>
                                    </div>
                                </div>
                                <div className="property-split-line"></div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12}>
                                <div className="property-desc-title">{props?.display_address}</div>
                                {renderComponent &&
                                    <PropertyDetailsMap
                                        lat={props?.latitude}
                                        lng={props?.longitude}
                                    />
                                }
                                {(process.env.GATSBY_SHOW_MAP_CONTENT === "true" && props?.display_address) &&
                                    <PropertyDetailsMapContent title={props?.display_address}/>
                                }
                            </Col>
                        </Row>
                    </Col>
                    <Col xl={1}></Col>
                    <Col xl={4}>
                        <div className={`property-sidebar position-sticky ${scroll ? "scrolled" : ""}`}>
                            {office_key >= 0
                                ? <PropertySidebar propofficedata={office_data[office_key]} />
                                : <PropertySidebar />
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default PropertyDescription