import React, { useState }  from "react";
//import { Link } from "gatsby"
import { useTranslation } from "react-i18next"
import { useLocalization } from "gatsby-theme-i18n"
import { LocalizedLink as Link } from "gatsby-theme-i18n";
import { Row, Col } from "react-bootstrap";
import loadable from "@loadable/component";
import './assets/styles/_index.scss';

const FsLightbox = loadable(() => import("fslightbox-react"));
const { Site_Vars } = require("../../common/site/config");

const PropertyKeyDetails = (props) => {
    const { t } = useTranslation()
    const { locale } = useLocalization()
    const [propertyImage, setPropertyImage] = useState(false);
    let propertyEPCImg = [];
    //if((data?.strapiProperty?.images).length > 0) {
    if(props?.epc?.strapi_json_value) {
        for (let i = 0; i < props?.epc?.strapi_json_value.length; i++) {
            propertyEPCImg.push(props?.epc?.strapi_json_value[i].srcUrl);
        }
    }

    return (
        <>
            <Row>
                <Col lg={12}>
                    <div className="property-desc-title">{t("key_info")}</div>
                </Col>
            </Row>

            <Row>
                <div className="desc-key-grid-wrapper">
                    <div className="desc-key-wrapper">
                        <div className="desc-key-title">{t("key_info")}</div>
                        <div className="desc-key-text">{Site_Vars.default_currency}{props?.price.toLocaleString()}</div>
                    </div>

                    {props?.building && props?.building?.strapi_json_value && props?.building?.strapi_json_value?.length > 0 &&
                    <div className="desc-key-wrapper">
                        <div className="desc-key-title">{t("key_info")}</div>
                        <div className="desc-key-text">{(props?.building?.strapi_json_value).join(", ")}</div>
                    </div>}

                    <div className="desc-key-wrapper">
                        <div className="desc-key-title">{t("bedrooms")}</div>
                        <div className="desc-key-text">{props?.bedroom}</div>
                    </div>

                    <div className="desc-key-wrapper">
                        <div className="desc-key-title">{t("bathrooms")}</div>
                        <div className="desc-key-text">{props?.bathroom}</div>
                    </div>

                    {props?.selling_info?.tenure?.type &&
                    <div className="desc-key-wrapper">
                        <div className="desc-key-title">{t("tenure")}</div>
                        <div className="desc-key-text">{props?.selling_info?.tenure?.type}</div>
                    </div>}

                    {propertyEPCImg?.length > 0 &&
                    <div className="desc-key-wrapper">
                        <div className="desc-key-title">{t("epc_rate")}</div>
                        <div className="desc-key-text"><Link to="#" className="link-underline" onClick={ () => setPropertyImage(!propertyImage) }>Energy Performance Certificate</Link></div>
                    </div>}

                    {(props?.floorarea_min != '0' && props?.floorarea_min != null) &&
                    <div className="desc-key-wrapper">
                        <div className="desc-key-title">{t("buildunit")}</div>
                        <div className="desc-key-text">{props?.floorarea_min?.toLocaleString()} {props?.floorarea_type}</div>
                    </div>}

                    <div className="desc-key-wrapper">
                        <div className="desc-key-title">{t("reference")}</div>
                        {/* <div className="desc-key-text">rps_tuh-{props.crm_id}</div> */}
                        <div className="desc-key-text">{props.crm_id}</div>
                    </div>
                </div>
            </Row>
            {/* Property Lightbox popup */}
            <FsLightbox
                toggler={ propertyImage }
                sources={ propertyEPCImg }
                type="image"
                types={[null]}
            />
            {/* Property Lightbox popup */}
        </>
    )
}

export default PropertyKeyDetails