import React from "react";
//import { Link } from "gatsby"
import { LocalizedLink as Link } from "gatsby-theme-i18n";
import { useStaticQuery, graphql } from "gatsby"
import { Row, Col } from "react-bootstrap";
import { PageLinks } from "../../common/site/page-static-links";
import { StaticImage } from "gatsby-plugin-image";
import PropertyValuationCard from "./PropertyValuationCard";
import { useLocalization } from "gatsby-theme-i18n"
import './assets/styles/_index.scss';
import GGFXImage from "../GGFX/GGFXImage";
const { ContentModule } = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const PropertySidebar = (props) => {

    const { locale } = useLocalization();

    const data = useStaticQuery(graphql`
        query {
            allStrapiSiteConfig {
                edges {
                    node {
                        property_details_contact {
                            data {
                                property_details_contact
                            }
                        }
                        locale
                    }
                }
            }
        }
    `);
    
    const filteredData = data.allStrapiSiteConfig.edges.find((n) => {
        return n.node.locale === locale;
    });
    
    // Access the 'property_details_contact' after filtering
    const contactdetails = filteredData?.node?.property_details_contact?.data?.property_details_contact;
    

    return (
        <div className="property-sidebar-wrapper">
            <Row>
                <Col xl={12} md={6}>
                    <div className="property-sidebar-card-wrapper">
                        <div className="property-sidebar-img">
                            {props?.propofficedata
                                ? <GGFXImage
                                ImageSrc={props?.propofficedata?.image}
                                altText={`office_image`}
                                imagetransforms={props?.propofficedata?.ggfx_results}
                                renderer="srcSet"
                                imagename={`office.image.full_image`}
                                strapiID={props?.propofficedata?.strapi_id}
                                className="img-fluid"
                                />
                                : <StaticImage src="../../images/property_detail_sidebar_img.png" layout="fullWidth" placeholder="blurred" formats={["webp"]} alt="banner" quality="90" className="img-fluid" />
                            }
                        </div>
                        {props?.propofficedata ?
                            <div className="property-sidebar-content">
                                <div className="property-sidebar-title">{props?.propofficedata ? props?.propofficedata.title : contactdetails.title}</div>
                                <p className="property-sidebar-phone">{props?.propofficedata ? props?.propofficedata.phone : contactdetails.phone}</p>
                                <p className="property-sidebar-mail"><Link to={`/${PageLinks.enquiry}/`} className="link-underline">{props?.propofficedata ? props?.propofficedata.email : contactdetails.email}</Link></p>
                                <p className="property-sidebar-address">{props?.propofficedata ? props?.propofficedata.address : contactdetails.address}</p>
                            </div> :
                            <div className="property-sidebar-content">
                                <ContentModule Content={contactdetails} />
                            </div>}
                    </div>
                </Col>
                <Col xl={12} md={6}>
                    <PropertyValuationCard />
                </Col>
            </Row>
        </div>
    )
}

export default PropertySidebar